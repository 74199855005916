<template>
  <div class="login-wrap">
    <div>
      <img :src="brand.darkLogo" alt="Simply Pills">
    </div>
    <div v-if="showPhone" class="login-card pa-6" :style="'background: ' + theme.client.content">
      <div class="text-center pb-6 title">
        Signup to SimplyPills
      </div>
      <v-alert
        border="left"
        color="green"
        v-model="successMessage.show"
        dense
        outlined
        dismissible
      >
        {{ successMessage.text }}
      </v-alert>
      <v-alert
        border="left"
        color="red"
        v-model="errorMessage.show"
        dense
        outlined
        dismissible
      >
        {{ errorMessage.text }}
      </v-alert>
      <div class="text-center pb-2 caption">
        To begin enter your phone number.
      </div>
      <validation-observer ref="validatorStep1">
        <validation-provider v-slot="{ errors }" name="Phone number" rules="required|phoneDigits:10">
          <div>
            <v-text-field
              outlined
              v-model="phone"
              v-mask="'##########'"
              prefix="+1"
              autocomplete="new-password"
              label="Enter your phone number"
              :error-messages="errors"
              @keyup.enter="tryStep1"
            />
          </div>
        </validation-provider>
      </validation-observer>
      <div class="text-right">
        <sp-btn block :loading="loadingStep1" @click="tryStep1">Proceed</sp-btn>
      </div>
      <div class="caption pt-6">
        By proceeding, you agree to our
        <a href="https://www.simplypills.com/legal/terms" target="_blank">Terms of Service</a>
        & <a href="https://www.simplypills.com/legal/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </div>

    <div v-else class="login-card pa-6" :style="'background: ' + theme.client.content">
      <div class="text-center pb-6 title">
        Verify your account
      </div>
      <v-alert
        border="left"
        color="green"
        v-model="successMessage.show"
        dense
        outlined
        dismissible
      >
        {{ successMessage.text }}
      </v-alert>
      <v-alert
        border="left"
        color="red"
        v-model="errorMessage.show"
        dense
        outlined
        dismissible
      >
        {{ errorMessage.text }}
      </v-alert>
      <div class="text-center pb-2 caption">
        We sent you a message with a verification code to the phone number: <b>+1{{ phone }}</b>.
        Please enter it below:
      </div>
      <validation-observer ref="validatorStep2">
        <validation-provider v-slot="{ errors }" name="Code" rules="required">
          <div>
            <v-text-field
              outlined
              v-model="code"
              autocomplete="new-password"
              label="Verification code"
              :error-messages="errors"
              @keyup.enter="tryStep2"
            />
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="Code" rules="email|required">
          <div>
            <v-text-field
              outlined
              v-model="email"
              autocomplete="new-password"
              label="Email"
              :error-messages="errors"
              @keyup.enter="tryStep2"
            />
          </div>
        </validation-provider>
        <validation-provider v-slot="{ errors }" name="Password" rules="required">
          <div>
            <v-text-field
              outlined
              v-model="password"
              label="Password"
              autocomplete="new-password"
              :error-messages="errors"
              :type="showPwd ? 'text' : 'password'"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPwd = !showPwd"
              @keyup.enter="tryStep2"
            />
          </div>
        </validation-provider>
      </validation-observer>
      <div class="text-right pb-6">
        <sp-btn block :loading="loadingStep2" @click="tryStep2">Verify</sp-btn>
      </div>
      <div class="text-center caption">
        Didn't get the code?
        <a href="#" @click="showPhone = true">Resend it</a>
        or <a href="#">call me instead</a>.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginView',
  data: () => ({
    phone: '',
    code: '',
    email: '',
    password: '',
    showPwd: false,
    loadingStep1: false,
    loadingStep2: false,
    showPhone: true,
    successMessage: {
      show: false,
      text: ''
    },
    errorMessage: {
      show: false,
      text: ''
    }
  }),
  computed: {
    ...mapGetters({
      brand: 'settings/brand',
      theme: 'settings/theme'
    })
  },
  methods: {
    ...mapActions({
      enroll: 'session/enroll'
    }),
    async tryStep1 () {
      const valid = await this.$refs.validatorStep1.validate()
      if (!valid) return
      this.errorMessage.show = false
      this.successMessage.show = false
      this.loadingStep1 = true
      const data = {
        mobile: this.phone
      }
      try {
        const response = await this.enroll(data)
        if (response.existing) {
          this.errorMessage.show = true
          this.errorMessage.text = 'This phone number is already registered.'
          return
        }
        this.successMessage.show = true
        this.successMessage.text = response.resultmsg
        this.showPhone = false
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.loadingStep1 = false
      }
    },
    async tryStep2 () {
      const valid = await this.$refs.validatorStep2.validate()
      if (!valid) return
      this.errorMessage.show = false
      this.successMessage.show = false
      this.loadingStep2 = true
      const data = {
        mobile: this.phone,
        code: this.code,
        email: this.email,
        password: this.password
      }
      try {
        await this.enroll(data)
        this.$router.push({ name: 'onboarding' })
      } catch (e) {
        this.errorMessage.show = true
        this.errorMessage.text = e
      } finally {
        this.loadingStep2 = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 100%;
  max-width: 440px;
  background: lightgray;
  border-radius: 6px;
}
</style>
