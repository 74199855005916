<template>
  <v-container class="px-6 pl-md-10" style="margin: initial">
    <h3 class="font-weight-medium pb-4">
      Your Patient Profile
    </h3>
    <v-row>
      <v-col cols="12">
        <profile />
      </v-col>
      <v-col cols="12">
        <health-information />
      </v-col>
      <v-col cols="12">
        <health-card />
      </v-col>
      <v-col cols="12">
        <insurance-information />
      </v-col>
      <v-col cols="12">
        <payment-method />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Profile from '@/components/Profile/Profile'
import HealthInformation from '@/components/Profile/HealthInformation'
import HealthCard from '@/components/Profile/HealthCard'
import InsuranceInformation from '@/components/Profile/InsuranceInformation'
import PaymentMethod from '@/components/Profile/PaymentMethod'

export default {
  name: 'ProfileView',
  components: {
    Profile,
    HealthInformation,
    HealthCard,
    InsuranceInformation,
    PaymentMethod
  }
}
</script>

<style scoped>

</style>
