<template>
  <gg-card>
    <gg-card-header>Health Information</gg-card-header>
    <gg-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Do you have any allergies to any medications</div>
          <div>Yes</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Supplements and/or medications that you are taking</div>
          <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque eveniet facere repudiandae vel velit. Doloremque error facilis, id ipsam quia voluptate? Dignissimos, molestias, voluptatem. Dolorum eum iste itaque quia repellat?</div>
        </v-col>
      </v-row>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'HealthInformation'
}
</script>

<style scoped>

</style>
