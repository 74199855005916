<template>
  <v-dialog max-width="600">
    <template v-slot:activator="slotProps">
      <slot v-bind="slotProps"></slot>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          Edit Insurance Information
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="close(dialog)">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" class="d-flex">
              <file-upload name="front-ie" rules="required">
                Front Health Card
              </file-upload>
              <file-upload name="back-ie" rules="required" class="ml-5">
                Back Health Card
              </file-upload>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <sp-btn
            secondary
            @click="close(dialog)"
          >
            Cancel
          </sp-btn>
          <sp-btn @click="save(dialog)">Save</sp-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import FileUpload from '@/components/Common/FileUpload'

export default {
  name: 'EditInsuranceDialog',
  components: {
    FileUpload
  },
  methods: {
    close (dialog) {
      dialog.value = false
    },
    save (dialog) {
      // this.close(dialog)
    }
  }
}
</script>
