import { render, staticRenderFns } from "./RegistrationView.vue?vue&type=template&id=6ca6f546&scoped=true&"
import script from "./RegistrationView.vue?vue&type=script&lang=js&"
export * from "./RegistrationView.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationView.vue?vue&type=style&index=0&id=6ca6f546&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca6f546",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VTextField})
