<template>
  <gg-card>
    <gg-card-header>
      Health Card
      <edit-health-card-dialog>
        <template v-slot:default="{ on, attrs }">
          <sp-btn
            class="float-right"
            v-bind="attrs"
            v-on="on"
          >Edit</sp-btn>
        </template>
      </edit-health-card-dialog>
    </gg-card-header>
    <gg-card-text>
      No health card on file
    </gg-card-text>
  </gg-card>
</template>

<script>
import EditHealthCardDialog from './EditHealthCardDialog'

export default {
  name: 'HealthCard',
  components: {
    EditHealthCardDialog
  }
}
</script>

<style scoped>

</style>
