var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-wrap"},[_c('div',[_c('img',{attrs:{"src":_vm.brand.darkLogo,"alt":"Simply Pills"}})]),(_vm.showPhone)?_c('div',{staticClass:"login-card pa-6",style:('background: ' + _vm.theme.client.content)},[_c('div',{staticClass:"text-center pb-6 title"},[_vm._v(" Signup to SimplyPills ")]),_c('v-alert',{attrs:{"border":"left","color":"green","dense":"","outlined":"","dismissible":""},model:{value:(_vm.successMessage.show),callback:function ($$v) {_vm.$set(_vm.successMessage, "show", $$v)},expression:"successMessage.show"}},[_vm._v(" "+_vm._s(_vm.successMessage.text)+" ")]),_c('v-alert',{attrs:{"border":"left","color":"red","dense":"","outlined":"","dismissible":""},model:{value:(_vm.errorMessage.show),callback:function ($$v) {_vm.$set(_vm.errorMessage, "show", $$v)},expression:"errorMessage.show"}},[_vm._v(" "+_vm._s(_vm.errorMessage.text)+" ")]),_c('div',{staticClass:"text-center pb-2 caption"},[_vm._v(" To begin enter your phone number. ")]),_c('validation-observer',{ref:"validatorStep1"},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|phoneDigits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"outlined":"","prefix":"+1","autocomplete":"new-password","label":"Enter your phone number","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryStep1($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}],null,false,1458532762)})],1),_c('div',{staticClass:"text-right"},[_c('sp-btn',{attrs:{"block":"","loading":_vm.loadingStep1},on:{"click":_vm.tryStep1}},[_vm._v("Proceed")])],1),_vm._m(0)],1):_c('div',{staticClass:"login-card pa-6",style:('background: ' + _vm.theme.client.content)},[_c('div',{staticClass:"text-center pb-6 title"},[_vm._v(" Verify your account ")]),_c('v-alert',{attrs:{"border":"left","color":"green","dense":"","outlined":"","dismissible":""},model:{value:(_vm.successMessage.show),callback:function ($$v) {_vm.$set(_vm.successMessage, "show", $$v)},expression:"successMessage.show"}},[_vm._v(" "+_vm._s(_vm.successMessage.text)+" ")]),_c('v-alert',{attrs:{"border":"left","color":"red","dense":"","outlined":"","dismissible":""},model:{value:(_vm.errorMessage.show),callback:function ($$v) {_vm.$set(_vm.errorMessage, "show", $$v)},expression:"errorMessage.show"}},[_vm._v(" "+_vm._s(_vm.errorMessage.text)+" ")]),_c('div',{staticClass:"text-center pb-2 caption"},[_vm._v(" We sent you a message with a verification code to the phone number: "),_c('b',[_vm._v("+1"+_vm._s(_vm.phone))]),_vm._v(". Please enter it below: ")]),_c('validation-observer',{ref:"validatorStep2"},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{attrs:{"outlined":"","autocomplete":"new-password","label":"Verification code","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryStep2($event)}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Code","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{attrs:{"outlined":"","autocomplete":"new-password","label":"Email","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryStep2($event)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('v-text-field',{attrs:{"outlined":"","label":"Password","autocomplete":"new-password","error-messages":errors,"type":_vm.showPwd ? 'text' : 'password',"append-icon":_vm.showPwd ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPwd = !_vm.showPwd},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.tryStep2($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}])})],1),_c('div',{staticClass:"text-right pb-6"},[_c('sp-btn',{attrs:{"block":"","loading":_vm.loadingStep2},on:{"click":_vm.tryStep2}},[_vm._v("Verify")])],1),_c('div',{staticClass:"text-center caption"},[_vm._v(" Didn't get the code? "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.showPhone = true}}},[_vm._v("Resend it")]),_vm._v(" or "),_c('a',{attrs:{"href":"#"}},[_vm._v("call me instead")]),_vm._v(". ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"caption pt-6"},[_vm._v(" By proceeding, you agree to our "),_c('a',{attrs:{"href":"https://www.simplypills.com/legal/terms","target":"_blank"}},[_vm._v("Terms of Service")]),_vm._v(" & "),_c('a',{attrs:{"href":"https://www.simplypills.com/legal/privacy-policy","target":"_blank"}},[_vm._v("Privacy Policy")])])}]

export { render, staticRenderFns }