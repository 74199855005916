<template>
  <gg-card>
    <gg-card-header>
      Insurance Information
      <edit-insurance-dialog>
        <template v-slot:default="{ on, attrs }">
          <sp-btn
            class="float-right"
            v-on="on"
            v-bind="attrs"
          >Edit</sp-btn>
        </template>
      </edit-insurance-dialog>
    </gg-card-header>
    <gg-card-text>
      No health insurance information on file.
    </gg-card-text>
  </gg-card>
</template>

<script>
import EditInsuranceDialog from './EditInsuranceDialog'

export default {
  name: 'InsuranceInformation',
  components: {
    EditInsuranceDialog
  }
}
</script>

<style scoped>

</style>
