<template>
  <div class="login-wrap">
    <div class="login-card pa-6" :style="'background: ' + theme.client.content">
      <div class="text-center mb-2">
        <img :src="brand.darkLogo" alt="">
      </div>
      <div class="text-center pb-6 subtitle-2">
        Login to your SimplyPills Account
      </div>
      <v-form autocomplete="off" @submit="tryLogin">
        <validation-observer ref="validator">
          <validation-provider v-slot="{ errors }" name="Phone number" rules="required|phoneDigits:10">
            <v-text-field outlined
                          v-mask="'##########'"
                          prefix="+1"
                          v-model="phone"
                          autocomplete="new-password"
                          placeholder="10 digit phone number"
                          label="Phone number"
                          :error-messages="errors"
                          @keyup.enter="tryLogin"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Password" rules="required">
            <v-text-field outlined
                          v-model="password"
                          label="Password"
                          autocomplete="new-password"
                          :hide-details="!errors.length"
                          :error-messages="errors"
                          :type="showPwd ? 'text' : 'password'"
                          :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="showPwd = !showPwd"
                          @keyup.enter="tryLogin"
            />
          </validation-provider>
        </validation-observer>
        <div class="text-right caption pt-2">
          <router-link :to="{ name: 'login' }">Forgot Password</router-link>
        </div>
        <sp-btn
          block
          class="mt-4"
          :loading="loading"
          @click="tryLogin">Login Securely</sp-btn>
        <div class="text-center pt-4 subtitle-2">
          or, <router-link :to="{ name: 'registration' }">Create Account</router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginView',
  data: () => ({
    phone: process.env.VUE_APP_DEV_USERNAME,
    password: '',
    showPwd: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      brand: 'settings/brand',
      theme: 'settings/theme'
    })
  },
  methods: {
    ...mapActions({
      login: 'session/login'
    }),
    async tryLogin () {
      const valid = await this.$refs.validator.validate()
      if (!valid) return
      this.loading = true
      try {
        await this.login({
          mobile: this.phone,
          password: this.password
        })
        await this.$router.push({ name: 'onboarding' })
      } catch (e) {
        this.$notifyError(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 100%;
  max-width: 440px;
  background: #FFFFFF !important;
  border-radius: 6px;
}
</style>
