<template>
  <gg-card>
    <gg-card-header>Your Profile</gg-card-header>
    <gg-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Name:</div>
          <div>John Doe</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Street Address 1</div>
          <div>Lorem ipsum dolor sit amet</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Street Address 2</div>
          <div>Lorem ipsum dolor sit amet</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">City</div>
          <div>Lorem ipsum dolor sit amet</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">Province</div>
          <div>Lorem ipsum dolor sit amet</div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">ZIP</div>
          <div>Lorem ipsum dolor sit amet</div>
        </v-col>
      </v-row>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'Profile'
}
</script>

<style scoped>

</style>
