<template>
  <gg-card>
    <gg-card-header>Payment Method</gg-card-header>
    <gg-card-text>
      <v-row>
        <v-col class="text-right pr-0 justify-end" md="2">
          <v-img
            :src="require('@/assets/img/credit-cards/visa.png')"
            max-height="150"
            contain
          />
        </v-col>
        <v-col class="text-left pl-0" md="10">
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Visa ...5478
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Expires 12/25</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'PaymentMethod'
}
</script>

<style scoped>

</style>
